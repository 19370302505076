import store from "@/store";

export default class StoreSubscriber {
  static subscribe(mutationName: string, callback: any): any {
    return store.subscribe((mutation: any, state: any) => {
      if (mutation.type === mutationName) {
        callback(mutation.payload);
      }
    });
  }
}
