export class DocumentMetadata {
  pecSentDate!: Date;
  pecSender!: string;
  pecSubject!: string;
  pecBody!: string;

  constructor() {
    this.pecSentDate = new Date();
    this.pecSender = "";
    this.pecSubject = "";
    this.pecBody = "";
  }
}

export class RequirementPlansMetadata {
  uploadDate!: Date;
  notes!: string;
  organizationPec!: string;

  constructor() {
    this.uploadDate = new Date();
    this.notes = "";
    this.organizationPec = "";
  }
}
