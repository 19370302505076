
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { camelToSnake } from "@/utils/stringUtils";
import WithErrors from "@/directives/WithErrors";
import moment from "moment";

@Component({
  directives: {
    WithErrors,
  },
})
export default class VFormGroup extends Vue {
  @Prop({ required: true }) readonly value: string | number | null;
  @Prop({ required: true }) readonly type:
    | "text"
    | "number"
    | "date"
    | "password";
  @Prop({ required: true }) readonly fieldName: string;
  @Prop({ required: true }) readonly labelText: string;

  @Prop() readonly httpTag: string | null;
  @Prop() readonly customError: string | null;
  @Prop({ default: '' }) readonly placeholder!: string;

  private changedValue: string | null = null;
  private focused = false;

  created() {
    if (this.value) {
      this.convertValue();
    }
  }

  @Watch("value")
  onVModelChange() {
    this.convertValue();
  }

  @Watch("changedValue")
  onValueChanged() {
    this.$emit("input", this.changedValue);
  }

  get snakeCasedFieldName() {
    return camelToSnake(this.fieldName);
  }

  private convertValue() {
    if (this.type === "date") {
      this.changedValue = moment(this.value).format("yyyy-MM-DD");
      return;
    }

    this.changedValue = this.value.toString();
  }

  get inputEmpty() {
    return this.changedValue === null || this.changedValue === "";
  }

  get isActive() {
    return (
      !(this.changedValue === null || this.changedValue === "") || this.focused
    );
  }
}
