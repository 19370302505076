import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { ContractCompany } from "@/models";
import * as qs from "qs";

export enum ContractCompanyTags {
  ContractCompanyIndex = "ContractCompanyIndex",
}

@Component
export default class ContractCompanyMixin extends Vue {
  getContractCompanies(data: any): Promise<ContractCompany[]> {
    const url = `${SD_BASE_API_URL}/contract_companies`;

    return httpStore.request({
      tag: ContractCompanyTags.ContractCompanyIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<ContractCompany[]>;
  }
}
