
import { Component, Mixins, Prop } from "vue-property-decorator";
import { camelToSnake } from "@/utils/stringUtils";
import DocumentAdminMixin, {
  DocumentAdminTags,
} from "@/mixins/http/admin/DocumentAdminMixin";
import Document, { DocumentType } from "@/models/Document";
import WithErrors from "@/directives/WithErrors";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { safeAsync } from "@/utils/AsyncUtil";
import moment from "moment";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import VSvg from "@/components/Common/VSvg.vue";
import {
  DocumentMetadata,
  RequirementPlansMetadata,
} from "@/models/DocumentMetadata";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    VFileUploader,
    VSvg,
  },
})
export default class DocumentForm extends Mixins(DocumentAdminMixin) {
  @Prop({ required: true }) readonly initiativeId!: string;
  @Prop({}) readonly document!: Document;
  @Prop({ default: false }) readonly readOnly!: boolean;
  @Prop({ default: DocumentType.RequirementsPlan }) readonly type!: string;

  @RegisterHttp(DocumentAdminTags.DocumentCreate) httpRequest!: RequestData;

  private otherDocMeta = new DocumentMetadata();
  private requirementaPlanMeta = new RequirementPlansMetadata();

  private name = "";
  private uploadDate = moment(Date.now()).format("YYYY-MM-DD");
  private file: any = null;

  get httpTag() {
    return DocumentAdminTags.DocumentCreate;
  }

  get fileName() {
    return this.file ? this.file.name : "";
  }

  get requirementsPlanType() {
    return this.type === "requirementsPlans";
  }

  mounted() {
    this.initDocument();
  }

  initDocument() {
    this.name = this.document?.name || "";
    this.requirementsPlanType
      ? this.feedRequirementsPlanMeta()
      : this.feedOtherDocsMeta();
  }

  private feedRequirementsPlanMeta() {
    this.uploadDate = moment(
      (this.document?.metadata as RequirementPlansMetadata).uploadDate ||
        Date.now()
    ).format("YYYY-MM-DD");
    this.requirementaPlanMeta.organizationPec =
      (this.document?.metadata as RequirementPlansMetadata).organizationPec ||
      "";
    this.requirementaPlanMeta.notes =
      (this.document?.metadata as RequirementPlansMetadata).notes || "";
  }

  private feedOtherDocsMeta() {
    this.uploadDate = moment(
      (this.document?.metadata as DocumentMetadata).pecSentDate || Date.now()
    ).format("YYYY-MM-DD");
    this.otherDocMeta.pecSender =
      (this.document?.metadata as DocumentMetadata).pecSender || "";
    this.otherDocMeta.pecSubject =
      (this.document?.metadata as DocumentMetadata).pecSubject || "";
    this.otherDocMeta.pecBody =
      (this.document?.metadata as DocumentMetadata).pecBody || "";
  }

  async submit() {
    const metadata = this.requirementsPlanType
      ? new RequirementPlansMetadata()
      : new DocumentMetadata();

    if (this.requirementsPlanType) {
      (metadata as RequirementPlansMetadata).uploadDate = moment(
        this.uploadDate
      ).toDate();
      (metadata as RequirementPlansMetadata).organizationPec =
        this.requirementaPlanMeta.organizationPec;
      (metadata as RequirementPlansMetadata).notes =
        this.requirementaPlanMeta.notes;
    } else {
      (metadata as DocumentMetadata).pecSentDate = moment(
        this.uploadDate
      ).toDate();
      (metadata as DocumentMetadata).pecSender = this.otherDocMeta.pecSender;
      (metadata as DocumentMetadata).pecSubject = this.otherDocMeta.pecSubject;
      (metadata as DocumentMetadata).pecBody = this.otherDocMeta.pecBody;
    }

    const backendType = Document.typeFromDocument(camelToSnake(this.type));

    const payload = {
      file: this.file?.file,
      type: backendType,
      initiativeId: this.initiativeId,
      name: this.name,
      metadata: metadata,
    };
    const [data, errors] = await safeAsync(this.createDocument(payload));

    if (!errors) {
      this.$emit("created", data);
    }
  }
}
