import { Component, Vue } from "vue-property-decorator";
import { httpStore } from "@/store/typed";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { Topic } from "@/models";
import { TopicFormData } from "@/models/Topic";

export enum TopicMixinTags {
  TopicIndex = "TopicIndex",
  TopicCreate = "TopicCreate",
  TopicDestroy = "TopicDestroy",
}

@Component
export default class TopicMixin extends Vue {
  getTopics(postId: number): Promise<Topic[]> {
    const url = `${SD_BASE_API_URL}/topics?filter[post_id]=${postId}`;
    return httpStore.request({
      tag: TopicMixinTags.TopicIndex,
      url,
    }) as Promise<Topic[]>;
  }

  createTopic(data: TopicFormData): Promise<Topic> {
    const url = `${SD_BASE_API_URL}/topics`;
    return httpStore.request({
      tag: TopicMixinTags.TopicCreate,
      url,
      method: "POST",
      data
    }) as Promise<Topic>;
  }

  destroyTopic(id: string | number) {
    const url = `${SD_BASE_API_URL}/topics/${id}`;
    return httpStore.request({
      tag: TopicMixinTags.TopicDestroy,
      url,
      method: "DELETE"
    }) as Promise<Topic>;
  }
}
