
import { Component, Vue, Prop, Ref, Mixins } from "vue-property-decorator";

import Post from "@/models/Post";

import PostTypology from "@/components/Post/Typology.vue";
import ExpandableText from "@/components/Common/ExpandableText.vue";
import LikeButton from "@/components/Like/Button.vue";
import VSvg from "@/components/Common/VSvg.vue";
import { currentUserStore } from "@/store/typed";
import { safeAsync } from "@/utils/AsyncUtil";
import PostMixin from "@/mixins/http/PostMixin";
import TopicMixin from "@/mixins/http/TopicMixin";
import { showErrorToast } from "@/utils/Toast";
import FileDownloader from "@/components/Common/FileDownloader.vue";
import PostLink from "@/components/Post/Link.vue";
import PostContactModal from "@/components/Post/ContactModal.vue";
import TopicButton from "@/components/Topic/Button.vue";
import { Topic } from "@/models";
import TopicList from "@/components/Topic/List.vue";
import TopicCreate from "@/components/Topic/Create.vue";
import ConfirmModal from "../Common/VConfirmModal.vue";

@Component({
  components: {
    PostTypology,
    ExpandableText,
    LikeButton,
    VSvg,
    FileDownloader,
    PostLink,
    PostContactModal,
    TopicButton,
    TopicList,
    TopicCreate,
    ConfirmModal
  },
})
export default class PostItem extends Mixins(PostMixin, TopicMixin) {
  @Prop({ required: true }) readonly value!: Post;
  @Ref() postContactModal!: PostContactModal;
  @Ref() topicCreateModal!: TopicCreate;
  @Ref() readonly confirmModal!: ConfirmModal;
  topics: Topic[] = [];
  showTopics = false;

  get isMobile(): boolean {
    return this.$mq === "sm";
  }

  get deletable(): boolean {
    return currentUserStore.user.id === this.value.user.id || currentUserStore.user.isAdmin;
  }

  get disabled(): boolean {
    return (
      currentUserStore.user?.isOccUser || currentUserStore.user?.isConsipUser
    );
  }

  get organizationName(): string {
    return this.value.user.organizationName || '';
  }

  get topicsCount(): number {
    return this.topics.length || this.value.topicsCount;
  }

  filename(url: string): string {
    const split = url.split("/");
    return unescape(split[split.length - 1]);
  }

  onDeletePost(): void {
    this.confirmModal.confirm(
      "Elimina Post",
      "Sei sicuro di voler eliminare il post selezionato? Tutte le discussioni e i relativi commenti verranno cancellati.",
      ''
    );
  }

  private async onDeletePostConfirmed() {
    const [data, errors] = await safeAsync(this.destroyPost(this.value.id));

    if (errors) {
      showErrorToast(
        "Sì è verificato un problema con la cancellazione del post."
      );
      return;
    }

    this.$emit("delete");
  }

  private openContactModal() {
    this.postContactModal.show();
  }

  private async onShowComments() {
    await this.loadTopics();
  }

  private async onSaved() {
    await this.loadTopics();
    if (this.topics.length == 0) {
      return;
    }
    const topicId = this.topics[this.topics.length - 1].id;
    const element = document.querySelector(`#topic-${topicId}`);
    element?.scrollIntoView({behavior: 'smooth'});
  }

  private async loadTopics() {
    const postId = Number.parseInt(`${this.value.id}`);
    this.topics = await this.getTopics(postId);
    this.showTopics = true;
  }

  private onNewTopic() {
    this.topicCreateModal.open();
  }

  private async onTopicDestroyed(topicId: string) {
    await this.destroyTopic(topicId);
    await this.loadTopics();
    this.value.topicsCount = this.topics.length;
  }
}
