import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { Like } from "@/models";
import qs from "qs";

export enum LikeMixinTags {
  LikeCreate = "LikeCreate",
  LikeDestroy = "LikeDestroy",
  LikeIndex = "LikeIndex",
}

@Component
export default class LikeMixin extends Vue {
  getLikes(data: any): Promise<Like[]> {
    const url = `${SD_BASE_API_URL}/likes`;

    return httpStore.request({
      tag: LikeMixinTags.LikeIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Like[]>;
  }

  createLike(data: { likeableId: string; likeableType: string; userId: string }): Promise<Like> {
    const url = `${SD_BASE_API_URL}/likes`;
    return httpStore.request({
      tag: LikeMixinTags.LikeCreate,
      url: url,
      method: "POST",
      data,
    }) as Promise<Like>;
  }

  destroyLike(id: string | number) {
    const url = `${SD_BASE_API_URL}/likes/${id}`;
    return httpStore.request({
      tag: LikeMixinTags.LikeDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Like>;
  }
}
