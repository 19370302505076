import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import { Document } from "@/models";
import {
  DocumentMetadata,
  RequirementPlansMetadata,
} from "@/models/DocumentMetadata";
import { DocumentType } from "@/models/Document";

export enum DocumentAdminTags {
  DocumentCreate = "DocumentCreate",
  DocumentReject = "DocumentReject",
  DocumentAccept = "DocumentAccept",
}

@Component
export default class DocumentsAdminMixin extends Vue {
  createDocument(payload: {
    file: any;
    type: string;
    initiativeId: string;
    name: string;
    metadata: DocumentMetadata | RequirementPlansMetadata;
  }): Promise<Document> {
    const url = `${SD_BASE_ADMIN_API_URL}/initiatives/${payload.initiativeId}/documents`;

    const data = new FormData();

    if (payload.file) {
      data.append("file", payload.file);
    }

    data.append("type", payload.type);
    data.append("initiativeId", payload.initiativeId);
    data.append("attachedAt", Date.now().toString());
    data.append("name", payload.name);

    if (payload.type === DocumentType.RequirementsPlan) {
      data.append(
        "metadata[upload_date]",
        (payload.metadata as RequirementPlansMetadata).uploadDate.toString()
      );
      data.append(
        "metadata[organization_pec]",
        (payload.metadata as RequirementPlansMetadata).organizationPec
      );
      data.append(
        "metadata[notes]",
        (payload.metadata as RequirementPlansMetadata).notes
      );
      data.append(
        "attachedAt",
        (payload.metadata as RequirementPlansMetadata).uploadDate.toString()
      );
    } else {
      data.append(
        "metadata[pec_sent_date]",
        (payload.metadata as DocumentMetadata).pecSentDate.toString()
      );
      data.append(
        "metadata[pec_sender]",
        (payload.metadata as DocumentMetadata).pecSender
      );
      data.append(
        "metadata[pec_subject]",
        (payload.metadata as DocumentMetadata).pecSubject
      );
      data.append(
        "metadata[pec_body]",
        (payload.metadata as DocumentMetadata).pecBody
      );
      data.append(
        "attachedAt",
        (payload.metadata as DocumentMetadata).pecSentDate.toString()
      );
    }

    return httpStore.request({
      tag: DocumentAdminTags.DocumentCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Document>;
  }

  rejectDocument(id: string, data: any): Promise<Document> {
    const url = `${SD_BASE_ADMIN_API_URL}/documents/${id}/reject`;
    return httpStore.request({
      tag: DocumentAdminTags.DocumentReject,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Document>;
  }

  acceptDocument(id: string): Promise<Document> {
    const url = `${SD_BASE_ADMIN_API_URL}/documents/${id}/accept`;
    return httpStore.request({
      tag: DocumentAdminTags.DocumentAccept,
      url: url,
      method: "PUT",
    }) as Promise<Document>;
  }
}
