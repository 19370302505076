
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { Comment, Topic } from "@/models";
import LikeButton from "@/components/Like/Button.vue";
import CommentMixin, { CommentMixinTags } from "@/mixins/http/CommentMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import TopicComment from "./Comment.vue";
import Spinner from "@/components/Common/Spinner.vue";
import VFormGroup from "../Common/VFormGroup.vue";
import { httpStore, currentUserStore } from "@/store/typed";
import VConfirmModal from "@/components/Common/VConfirmModal.vue";

@Component({
  components: {
    TopicComment,
    Spinner,
    VFormGroup,
    VConfirmModal,
    LikeButton,
  }
})
export default class Item extends Mixins(CommentMixin) {
  @Prop({ required: true }) readonly topic!: Topic;
  @Ref() readonly confirm!: VConfirmModal;

  comments: Comment[] = [];
  commentContent = '';

  @Ref() readonly confirmModal!: VConfirmModal;

  mounted() {
    this.loadComments();
  }

  deleteTopicConfirm() {
    this.confirm.confirm("Sei sicuro?", "Vuoi cancellare questa discussione?", this.topic.id);
  }

  get deletable() {
    return currentUserStore.user.id === this.topic.user.id || currentUserStore.user.isAdmin;
  }

  get organizationName(): string {
    return this.topic.user?.organizationName ?? "";
  }

  get commentsLoading(): boolean {
    return httpStore.status[this.commentsIndexTag]?.loading || false;
  }

  get commentCreateTag(): string {
    return `${CommentMixinTags.CommentCreate}_topic_${this.topic.id}`;
  }

  get commentsIndexTag(): string {
    return `${CommentMixinTags.CommentIndex}_topic_${this.topic.id}`;
  }

  async onPublishComment(): Promise<void> {
    const payload: Partial<Comment> = {
      commentableId: this.topic.id,
      commentableType: 'Topic',
      content: this.commentContent      
    };

    const [_data, errors] = await safeAsync(this.createComment(payload, this.commentCreateTag));

    if (errors) {
      showErrorToast('Si è verificato un problema con la pubblicazione del commento');
      return;
    }

    this.commentContent = '';
    showToast('Commento pubblicato!');
    this.loadComments();
  }

  async loadComments(): Promise<void> {
    const filtersPayload = {
      commentable: this.topic.id,
      commentable_type: 'Topic'
    }

    const [data, _errors] = await safeAsync(this.getComments('all', filtersPayload, this.commentsIndexTag));

    if (data) {
      this.comments = [...data];
    } else {
      this.comments = [];
      showErrorToast('Si è verificato un errore con il caricamento dei commenti');
    }
  }

  onCommentDelete(id: string | number): void {
    this.confirmModal.confirm(
      "Elimina commento",
      "Sei sicuro di voler eliminare il commento selezionato?",
      id
    );
  }

  async onCommentDeleteConfirmed(id: string | number): Promise<void> {
    const [_data, errors] = await safeAsync(this.destroyComment(id));

    if (errors) {
      showErrorToast('Si è verificato un errore durante la cancellazione del commento');
      return;
    }

    this.loadComments();
  }

  async onDestroyConfirmed(): Promise<void> {
    this.$emit("destroyed", this.topic.id);
  }
}
