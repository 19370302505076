
import { Component, Prop, Vue } from "vue-property-decorator";
import { Comment } from "@/models";
import VSvg from "../Common/VSvg.vue";
import { currentUserStore } from "@/store/typed";
import LikeButton from "../Like/Button.vue";

@Component({
  components: {
    VSvg,
    LikeButton
  }
})
export default class TopicComment extends Vue {
  @Prop({ required: true }) readonly value!: Comment;

  get organizationName(): string {
    return this.value.author?.organizationName ?? "";
  }

  get canDelete(): boolean {
    return currentUserStore.user.isAdmin || this.value.author?.id === currentUserStore.user.id;
  }

  onCommentDelete(): void {
    this.$emit('delete', this.value.id);
  }
}
