var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-left"},[_c('div',{staticClass:"like-button d-inline-flex",on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('v-icon',{ref:"likeIcon",staticClass:"my-auto",class:[
        { 'primary-color': _vm.alreadyLiked || _vm.hovered },
        { clickable: !_vm.isConsipUser },
      ],attrs:{"size":_vm.iconSize},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.alreadyLiked ? "like-fill" : "like"))]),_c('p',{staticClass:"my-auto font-weight-semibold",class:[
        { 'underlined-likes': _vm.likesNumberHovered && !_vm.isConsipUser },
        { clickable: !_vm.isConsipUser },
      ],on:{"mouseover":function($event){_vm.likesNumberHovered = true},"mouseleave":function($event){_vm.likesNumberHovered = false},"click":function($event){$event.stopPropagation();return _vm.showLikesModal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.likes.length)+" ")])],1),_c('like-modal',{ref:"likeModal",attrs:{"likeableId":_vm.likeableId,"likeableType":_vm.likeableType}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }