
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import ContractAdminMixin from "@/mixins/http/admin/ContractAdminMixin";
import ContractCompanyMixin from "@/mixins/http/ContractCompanyMixin";
import { ContractCompany } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";
import ContractCompanyModal from "@/components/Contract/CompanyModal.vue";
import VIcon from "@/components/VIcon.vue";
import { showErrorToast } from "@/utils/Toast";

@Component({
  components: {
    ContractCompanyModal,
    VIcon,
  },
})
export default class ContractCompanies extends Mixins(
  ContractAdminMixin,
  ContractCompanyMixin
) {
  @Prop({ required: true }) readonly contractId!: string;

  @Ref() readonly contractCompanyModal!: ContractCompanyModal;

  private companies: ContractCompany[] = [];

  created() {
    this.load();
  }

  private openAttachModal() {
    this.contractCompanyModal.show();
  }

  private async load() {
    const filters = {
      page: {
        size: "all",
      },
      filter: {
        contract: this.contractId,
      },
    };

    const [data, errors] = await safeAsync(this.getContractCompanies(filters));
    if (data) {
      this.companies = data;
    }
  }

  private async removeCompany(id: string) {
    const [data, errors] = await safeAsync(
      this.detachCompany(this.contractId, id)
    );

    if (data) {
      this.load();
      this.$emit("removed", data);
    } else {
      showErrorToast("Sì è verificato un errore");
    }
  }

  private onCompanyAttached(item: ContractCompany) {
    this.load();
    this.$emit("attached", item);
  }
}
