
import { Component, Vue, Prop } from "vue-property-decorator";
import { Topic } from "@/models";
import Item from "./Item.vue";

@Component({ components: { Item } })
export default class List extends Vue {
  @Prop({ default: [] }) readonly topics!: Topic[];

  onNewTopic() {
    this.$emit("new-topic");
  }

  onDestroyedItem(topicId: string) {
    this.$emit("destroyed", topicId);
  }
}
