
import { Vue, Component, Ref, Prop } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import Vsvg from "@/components/Common/VSvg.vue";

@Component({
  components: {
    VModal,
    Vsvg,
  },
})
export default class DescriptionModal extends Vue {
  @Ref() readonly modal!: VModal;

  private content = "";
  private title = "";

  show(content: string, title: string) {
    this.content = content;
    this.title = title;
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
