
import { Vue, Component, Ref, Prop } from "vue-property-decorator";

export const MAX_LINE_COUNT = 4;
export const FONT_SIZE_TO_LINE_HEIGHT_MULTIPLIER = 1.3;

@Component
export default class ExpandableText extends Vue {
  @Prop({ default: "" }) value!: string;

  @Ref() paragraph!: HTMLElement;

  private lines = 0;
  private readMore = false;

  mounted() {
    this.$nextTick(() => {
      this.lines = this.computeTextLinesCount();
    });
  }

  get text() {
    return this.largeText && !this.readMore ? this.slicedText : this.value;
  }

  get totalLines() {
    return this.lines;
  }

  get slicedText() {
    if (!this.value) {
      return null;
    }

    return `${this.value.slice(0, 450)} ...`;
  }

  get largeText() {
    return this.lines > MAX_LINE_COUNT;
  }

  computeTextLinesCount() {
    if (!this.paragraph) {
      return 0;
    }

    const lineHeight = 18 * FONT_SIZE_TO_LINE_HEIGHT_MULTIPLIER;
    return Math.floor(this.paragraph.clientHeight / lineHeight);
  }

  toggleReadMore() {
    this.readMore = !this.readMore;
  }
}
