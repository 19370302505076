
import { Component, Vue, Prop } from 'vue-property-decorator';
import VSvg from "@/components/Common/VSvg.vue";

@Component({ components: { VSvg } })
export default class Button extends Vue {
  @Prop({default: 0 }) readonly topicsCount!: number;
  
  onComments() {
    this.$emit("showComments");
  }
}
