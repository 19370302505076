import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import Contract from "@/models/Contract";
import ContractUser from "@/models/ContractUser";
import { ContractCompany } from "@/models";

export enum ContractAdminTags {
  ContractShow = "ContractShow",
  ContractIndex = "ContractIndex",
  ContractCreate = "ContractCreate",
  ContractUpdate = "ContractUpdate",
  ContractAttachUser = "ContractAttachUser",
  ContractDetachUser = "ContractDetachUser",
  ContractAttachCompany = "ContractAttachCompany",
  ContractDetachCompany = "ContractDetachCompany",
  ContractComplete = "ContractComplete",
}

@Component
export default class ContractAdminMixin extends Vue {
  getContract(id: string): Promise<Contract> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}`;

    return httpStore.request({
      tag: ContractAdminTags.ContractShow,
      url: url,
      method: "GET",
    }) as Promise<Contract>;
  }

  contractsList(page: number | null): Promise<Contract[]> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts`;

    const data = {
      page: {
        size: page ? page : "all",
      },
    };

    return httpStore.request({
      tag: ContractAdminTags.ContractIndex,
      url: url,
      method: "GET",
      data: data,
    }) as Promise<Contract[]>;
  }

  createContract(
    initiativeId: string,
    startDate: Date,
    endDate: Date,
    file: any
  ): Promise<Contract> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts`;

    const formData = new FormData();

    formData.append("initiative_id", initiativeId);
    formData.append("start_date", startDate?.toString());
    formData.append("end_date", endDate?.toString());

    if (file) {
      formData.append("file", file.file);
      formData.append("file_name", file.name);
    }

    return httpStore.request({
      tag: ContractAdminTags.ContractCreate,
      url: url,
      method: "POST",
      data: formData,
    }) as Promise<Contract>;
  }

  updateContract(
    id: string,
    managerId?: string,
    initiativeId?: string
  ): Promise<Contract> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}`;

    const data = {
      manager_id: managerId,
      initiative_id: initiativeId,
    };

    return httpStore.request({
      tag: ContractAdminTags.ContractUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Contract>;
  }

  deleteContract(id: string): Promise<Contract> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}`;

    return httpStore.request({
      tag: ContractAdminTags.ContractUpdate,
      url: url,
      method: "DELETE",
    }) as Promise<Contract>;
  }

  attachUser(id: string, userId: string): Promise<ContractUser> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}/attach_user`;

    const data = {
      user_id: userId,
    };

    return httpStore.request({
      tag: ContractAdminTags.ContractAttachUser,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<ContractUser>;
  }

  detachUser(id: string, userId: string): Promise<ContractUser> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}/detach_user`;

    const data = {
      user_id: userId,
    };

    return httpStore.request({
      tag: ContractAdminTags.ContractDetachUser,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<ContractUser>;
  }

  attachCompany(
    id: string,
    companyId: string,
    share: number
  ): Promise<ContractCompany> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}/attach_company`;

    const data = {
      company_id: companyId,
      share: share,
    };

    return httpStore.request({
      tag: ContractAdminTags.ContractAttachCompany,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<ContractCompany>;
  }

  detachCompany(id: string, companyId: string): Promise<ContractCompany> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}/detach_company`;

    const data = {
      company_id: companyId,
    };

    return httpStore.request({
      tag: ContractAdminTags.ContractDetachCompany,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<ContractCompany>;
  }

  complete(id: string): Promise<Contract> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${id}/complete`;

    return httpStore.request({
      tag: ContractAdminTags.ContractComplete,
      url: url,
      method: "POST",
    }) as Promise<Contract>;
  }
}
