
import { Component, Ref, Mixins, Prop } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import VNumericInput, {
  VNumericInputApplication,
} from "@/components/Common/VNumericInput.vue";
import CompanySelect from "@/components/Company/Select.vue";
import WithErrors from "@/directives/WithErrors";
import ContractsAdminMixin, {
  ContractAdminTags,
} from "@/mixins/http/admin/ContractAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({
  components: {
    VModal,
    VNumericInput,
    CompanySelect,
  },
  directives: {
    WithErrors,
  },
})
export default class ContractCompanyModal extends Mixins(ContractsAdminMixin) {
  @Prop({ required: true }) readonly contractId!: string;
  @Ref() readonly modal!: VModal;

  private share = 0;
  private companyId: string | null = null;

  get vNumericInputUsage() {
    return VNumericInputApplication.PERCENT;
  }

  get httpTag() {
    return ContractAdminTags.ContractAttachCompany;
  }

  show() {
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  private async onCreate() {
    const [data, errors] = await safeAsync(
      this.attachCompany(this.contractId, this.companyId, this.share)
    );

    if (data) {
      this.$emit("attached", data);
      this.hide();
    }
  }

  private onCompanySelected(id: string) {
    this.companyId = id;
  }
}
