
import { Vue, Component, Mixins, Ref, Prop } from "vue-property-decorator";

import LikeMixin, { LikeMixinTags } from "@/mixins/http/LikeMixin";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";
import { Like } from "@/models";
import VSvg from "@/components/Common/VSvg.vue";

@Component({
  components: {
    VModal,
    VSvg,
  },
})
export default class LikeModal extends Mixins(LikeMixin) {
  @Prop({ required: true }) likeableId!: string;
  @Prop({ required: true }) likeableType!: string;

  @Ref() readonly modal!: VModal;

  private likes: Like[] = [];

  @RegisterHttp(LikeMixinTags.LikeIndex)
  readonly likesIndexRequest!: RequestData;

  async show() {
    this.modal.show();
    await this.load();
  }

  hide() {
    this.modal.hide();
  }

  get loading() {
    return this.likesIndexRequest?.loading;
  }

  private async load() {
    const payload: any = {
      filter: {
        likeableId: this.likeableId,
        likeableType: this.likeableType
      },
    };

    const [data, errors] = await safeAsync(this.getLikes(payload));

    if (data) {
      this.likes = (data as Like[]).filter(
        (el: Like) => el.user.organization !== null
      );
    } else if (errors) {
      showErrorToast("Si è verificato un errore nel caricare i like del post");
      console.log(errors);
    }
  }

  private getOrgName(like: Like) {
    return like.user?.organizationName || "";
  }
}
