
import { Vue, Component, Prop, Mixins, Ref } from "vue-property-decorator";
import { Like } from "../../models";
import { currentUserStore } from "../../store/typed";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";
import LikeMixin from "@/mixins/http/LikeMixin";

import LikeModal from "@/components/Like/Modal.vue";

@Component({
  components: {
    LikeModal,
  },
})
export default class LikeButton extends Mixins(LikeMixin) {
  @Prop({ required: true }) readonly likeableId!: string;
  @Prop({ required: true }) readonly likeableType!: string;
  @Prop({ default: 'lg' }) readonly iconSize!: 'sm' | 'md' | 'lg' | 'xl';

  private likes: Like[] = [];
  private hovered = false;
  private likesNumberHovered = false;

  @Ref() readonly likeModal!: LikeModal;

  async created() {
    const [data, errors] = await safeAsync(
      this.getLikes({ filter: { likeableId: this.likeableId, likeableType: this.likeableType } })
    );
    if (data) {
      this.likes = data;
    }
  }

  get alreadyLiked() {
    return !!this.myLike;
  }

  get myLike() {
    return this.likes.find((o: Like) => o.user.id == this.currentUser?.id);
  }

  toggle() {
    if (this.isConsipUser) {
      return;
    }

    if (this.alreadyLiked) {
      this.dislike();
    } else {
      this.like();
    }
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get createPayload() {
    return {
      likeableId: this.likeableId,
      likeableType: this.likeableType,
      userId: this.currentUser?.id,
    };
  }

  get isConsipUser() {
    return (
      currentUserStore.currentUser.isConsipUser ||
      currentUserStore.currentUser.isOccUser
    );
  }

  async like() {
    const [data, error] = await safeAsync(this.createLike(this.createPayload));
    if (data) {
      this.likes.push(data);
    } else {
      showErrorToast("Non è stato possibile effettuare l'operazione");
    }
  }

  async dislike() {
    const [data, error] = await safeAsync(this.destroyLike(this.myLike?.id));
    if (data) {
      const index = this.likes.findIndex((o: Like) => o.id === data.id);
      this.likes.splice(index, 1);
    } else {
      showErrorToast("Non è stato possibile effettuare l'operazione");
    }
  }

  private showLikesModal() {
    if (this.isConsipUser) {
      return;
    }
    this.likeModal.show();
  }
}
