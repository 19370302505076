
import { Vue, Component, Prop } from "vue-property-decorator";

import moment from "moment";

import VSvg from "@/components/Common/VSvg.vue";
import { Post } from "@/models";

@Component({
  components: {
    VSvg,
  },
})
export default class PostLink extends Vue {
  @Prop({ required: true }) readonly post!: Post;

  get linkIcon() {
    return this.post.isWebinar ? "it-link" : "it-map-marker-circle";
  }

  get postLink() {
    if (this.post.isWebinar) {
      return { url: this.post.metadata.url, name: this.post.metadata.url };
    } else if (this.post.isEvent) {
      return {
        url: `https://maps.google.com/?q=${this.post.metadata.address}`,
        name: this.post.metadata.address,
      };
    }

    return null;
  }

  get date() {
    return moment(this.post.metadata.date).format("ll");
  }

  get showable() {
    return !this.post.isBreakingNews;
  }
}
