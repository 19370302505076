import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { Post } from "@/models";
import qs from "qs";
import { PostPayload } from "@/models/Post";

export enum PostMixinTags {
  PostCreate = "PostCreate",
  PostDestroy = "PostDestroy",
  PostIndex = "PostIndex",
  PostAddImage = "PostAddImage",
  PostContactOwner = "PostContactOwner",
}

@Component
export default class PostMixin extends Vue {
  getPosts(data: any): Promise<Post[]> {
    const url = `${SD_BASE_API_URL}/posts`;
    return httpStore.request({
      tag: PostMixinTags.PostIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Post[]>;
  }

  createPost(data: PostPayload): Promise<Post> {
    const url = `${SD_BASE_API_URL}/posts`;
    return httpStore.request({
      tag: PostMixinTags.PostCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Post>;
  }

  addMedia(id: string, data: any): Promise<any> {
    const url = `${SD_BASE_API_URL}/posts/${id}/add_media`;
    return httpStore.request({
      tag: PostMixinTags.PostAddImage,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<any>;
  }

  destroyPost(id: string | number) {
    const url = `${SD_BASE_API_URL}/posts/${id}`;
    return httpStore.request({
      tag: PostMixinTags.PostDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Post>;
  }

  contactOwner(postId: string, message: string) {
    const url = `${SD_BASE_API_URL}/posts/${postId}/contact_owner`;

    const data = {
      message: message,
    };

    return httpStore.request({
      tag: PostMixinTags.PostContactOwner,
      url: url,
      data: data,
      method: "POST",
    }) as Promise<any>;
  }
}
