
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";

interface ColumnData {
  sortable: boolean;
  sort: string;
  name: string;
  class: string;
  default: string;
  label: string;
}

@Component
export default class VDataTable extends Vue {
  @Prop({ required: true }) readonly columns!: ColumnData[];
  @Prop({ default: "table-sm table-hover" }) readonly tableClasses!: string;
  @Prop({ required: true }) readonly values!: any[];
  @Prop({ default: "" }) readonly sort!: string;
  @Prop({ default: true }) readonly sortable!: boolean;
  @Prop({ default: true }) readonly loading!: boolean;

  private _sort: Map<string, string> = new Map();

  @Ref() readonly tableContainer!: HTMLElement;

  get columnsTotal() {
    let total = this.columns.length;

    if (this.hasActions()) {
      total++;
    }

    return total;
  }

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  @Watch("sort")
  onSortChanged() {
    this.parseSort();
  }

  created() {
    this.parseSort();
  }

  mounted() {
    if (sessionStorage) {
      this.tableContainer.scrollLeft = parseInt(
        sessionStorage.getItem("vDataTableScrollPosition")
      );
    }
  }

  select(value: any) {
    this.$emit("selected", value);
  }

  parseSort() {
    this._sort = new Map();

    this.sort.split(",").map((item) => {
      const order = item[0] === "-" ? "-" : "";
      const key = order ? item.substr(1) : item;
      this._sort.set(key, order);
    });
  }

  sortBy(column: ColumnData) {
    if (!this.sortable) {
      return;
    }

    if (sessionStorage) {
      sessionStorage.setItem(
        "vDataTableScrollPosition",
        this.tableContainer.scrollLeft.toString()
      );
    }

    const name = column.sort || column.name;
    let order = this._sort.get(name);

    if (order == null) {
      order = "";
    } else {
      order = order === "-" ? "" : "-";
    }

    const sort = `${order}${name}`;
    this.$emit("sorted", sort);
  }

  isSortedBy(column: ColumnData) {
    const name = column.sort || column.name;
    return this._sort.get(name) != null;
  }

  isSortedByDesc(column: ColumnData) {
    const name = column.sort || column.name;
    return this._sort.get(name) === "-";
  }

  getColumnClass(column: ColumnData) {
    const columnClass: { [key: string]: boolean } = {
      "col-order-by": this.isSortedBy(column),
    };

    columnClass[`col-data__${column.name}`] = true;

    if (column.class) {
      columnClass[column.class] = true;
    }

    return columnClass;
  }

  getValue(column: ColumnData, value: any) {
    if (column.name.indexOf(".") !== -1) {
      const methodChain = column.name.split(".");
      let result = value;
      for (const method of methodChain) {
        result = result ? result[method] : null;
      }
      return result || column.default;
    }
    return value[column.name] || column.default;
  }

  hasActions() {
    return this.$scopedSlots.actions != null;
  }

  getSlotName(column: ColumnData) {
    return `column_${column.name}`;
  }

  hasSlot(column: ColumnData) {
    const slotName = this.getSlotName(column);
    return this.$scopedSlots[slotName] != null;
  }
}
