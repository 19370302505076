
import { Component, Vue, Prop } from "vue-property-decorator";

import Post from "@/models/Post";

import Vsvg from "@/components/Common/VSvg.vue";

@Component({
  components: {
    Vsvg,
  },
})
export default class PostTypology extends Vue {
  @Prop({ required: true }) post!: Post;

  get typology() {
    if (this.post.isBreakingNews) {
      return { name: "Breaking News", icon: "it-horn", color: "#7A73FF" };
    } else if (this.post.isWebinar) {
      return { name: "Webinar", icon: "it-video", color: "#FF9700" };
    } else {
      return { name: "Evento", icon: "it-map-marker-circle", color: "#21DB9A" };
    }
  }
}
