
import { Vue, Component, Prop } from "vue-property-decorator";
import customScroll from "@/initializers/ScrollToUtil";

import VSvg from "@/components/Common/VSvg.vue";

export interface StickyMenuLink {
  icon: string;
  routeName?: string;
  name: string;
  section?: string;
  hide?: boolean;
}

@Component({
  components: {
    VSvg,
  },
})
export default class StickyMenu extends Vue {
  @Prop({ required: true }) links!: StickyMenuLink[];

  goToSection(section: string) {
    const options = { offset: -80 };
    customScroll.scroll(section);
  }

  mounted() {
    if (this.section) {
      this.goToSection(`#${this.section}`);
    }
  }

  get section() {
    return this.$route.query.section;
  }
}
