
import { Vue, Component, Ref, Mixins } from "vue-property-decorator";
import VCarousel, { VCarouselSlide } from "@/components/VCarousel.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import UserCreateModal from "@/components/User/Modal.vue";
import { currentUserStore } from "@/store/typed";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";
import InitiativeList from "@/components/Initiative/List.vue";
import CreateModal from "@/components/Document/CreateModal.vue";
import InitiativeModal from "@/components/Initiative/Modal.vue";
import ContractCompanies from "@/components/Contract/Companies.vue";
import ChatBot from "@/components/Common/ChatBot.vue";
import VFormGroup from "@/components/Common/VFormGroup.vue";
import PostTypology from "@/components/Post/Typology.vue";
import PostItem from "@/components/Post/Item.vue";
import DigitalSpecificIndicators from "@/components/WatcherArea/DigitalSpecificIndicators.vue";

import { DocumentType } from "@/models/Document";
import Post from "@/models/Post";

import PostMixin from "@/mixins/http/PostMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import DigitalSpecificIndicator from "@/models/DigitalSpecificIndicator";

@Component({
  components: {
    VCarousel,
    VCarouselSlide,
    ImageContainer,
    UserCreateModal,
    StickyMenu,
    InitiativeList,
    CreateModal,
    InitiativeModal,
    ContractCompanies,
    ChatBot,
    VFormGroup,
    PostTypology,
    PostItem,
    DigitalSpecificIndicators,
  },
})
export default class Styleguide extends Mixins(PostMixin) {
  @Ref() readonly userModal!: UserCreateModal;
  @Ref() readonly userModalUpdate!: UserCreateModal;
  @Ref() readonly documentsPdfModal!: CreateModal;
  @Ref() readonly documentsPoModal!: CreateModal;
  @Ref() readonly initiativesModal!: InitiativeModal;

  private poType = DocumentType.OperativePlan;
  private ceType = DocumentType.ExecutiveContract;

  private links: StickyMenuLink[] = [
    { icon: "it-files", routeName: "home", name: "Modello operativo" },
    { icon: "it-folder", routeName: "home", name: "Catalogo servizi" },
    { icon: "it-software", routeName: "home", name: "Configuratore" },
    { icon: "it-bookmark", routeName: "home", name: "Biblioteca digitale" },
    { icon: "it-horn", routeName: "home", name: "Eventi" },
    { icon: "it-video", routeName: "home", name: "PA Digital Stories" },
  ];

  private customFormGroupValue = "adasd";
  private customFormGroupValueDate = new Date().getTime();
  private customFormGroupValueNumber = "0";

  private posts: Post[] = [];

  private digitalIndicators: DigitalSpecificIndicator[] = [];

  async created() {
    const [data, error] = await safeAsync(
      this.getPosts({ include: "initiative.organization" })
    );
    if (data) {
      this.posts = data;
    }

    for (let i = 0; i < 33; i++) {
      this.digitalIndicators.push(DigitalSpecificIndicator.dummy());
    }
  }

  get user() {
    return currentUserStore.user;
  }

  private openUserModal() {
    (this.userModal as any).create();
  }

  private openUserUpdateModal() {
    (this.userModalUpdate as any).edit(currentUserStore.user);
  }

  private openRequirementsPlanDocumentModal() {
    (this.documentsPdfModal as any).show();
  }

  private openOperativePlanDocumentModal() {
    (this.documentsPoModal as any).show();
  }

  private openInitiativesModal() {
    (this.initiativesModal as any).show();
  }
}
