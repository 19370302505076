import Comment from "@/models/Comment";
import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";

export enum CommentMixinTags {
  CommentCreate = "CommentCreate",
  CommentIndex = "CommentIndex",
  CommentRead = "CommentRead",
  CommentDestroy = "CommentDestroy"
}

@Component
export default class CommentMixin extends Vue {
  getComments(page: number | null | string, filter: any, customTag?: string): Promise<Comment[]> {
    const url = `${SD_BASE_API_URL}/comments`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: customTag || CommentMixinTags.CommentIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Comment[]>;
  }

  createComment(data: Partial<Comment>, customTag?: string): Promise<Comment> {
    const url = `${SD_BASE_API_URL}/comments`;

    return httpStore.request({
      tag: customTag || CommentMixinTags.CommentCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Comment>;
  }

  readComment(id: string): Promise<Comment> {
    const url = `${SD_BASE_API_URL}/comments/${id}/read`;

    const data = {};

    return httpStore.request({
      tag: CommentMixinTags.CommentRead,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Comment>;
  }

  destroyComment(id: number | string, customTag?: string): Promise<Comment> {
    const url = `${SD_BASE_API_URL}/comments/${id}`;

    return httpStore.request({
      tag: customTag || CommentMixinTags.CommentDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Comment>;
  }
}
