
import { Vue, Component, Mixins, Ref, Prop } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import PostMixin, { PostMixinTags } from "@/mixins/http/PostMixin";

@Component({
  components: {
    VModal,
  },
})
export default class PostContactModal extends Mixins(PostMixin) {
  @Prop({ required: true }) readonly postId!: string;

  @Ref() readonly modal!: VModal;

  private message = "";

  @RegisterHttp(PostMixinTags.PostContactOwner)
  readonly contactOwnerRequest!: RequestData;

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  get loading() {
    return this.contactOwnerRequest?.loading;
  }

  private async sendEmail() {
    const [data, errors] = await safeAsync(
      this.contactOwner(this.postId, this.message)
    );

    if (errors) {
      showErrorToast("Errore durante l'invio dell'email");
      return;
    }

    showToast("Email inviata con successo!");
    this.hide();
  }
}
