
import { Component, Prop, Ref, Mixins } from "vue-property-decorator";
import { TopicFormData } from "@/models/Topic";
import { RegisterHttp } from "@/utils/Decorators";
import TopicMixin, { TopicMixinTags } from "@/mixins/http/TopicMixin";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import RequestData from "@/store/types/RequestData";

@Component({ components: { VModal } })
export default class Create extends Mixins(TopicMixin) {
  @RegisterHttp(TopicMixinTags.TopicCreate) readonly createTopicRequest!: RequestData;

  @Prop({ default: "" }) readonly postId!: string | number;
  title = "Nuova discussione";
  content = "";

  @Ref() readonly modal!: VModal;

  open() {
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  cancel() {
    this.content = "";
    this.hide();
  }

  get loading() {
    return this.createTopicRequest?.loading;
  }

  async save() {
    const payload: TopicFormData = {
      content: this.content,
      postId: this.postId,
    };
    await this.createTopic(payload);
    this.hide();
    this.content = "";
    this.$emit("saved");
  }
}
