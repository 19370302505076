import { Model } from "@/models";
import { MapData, MapDataEntry } from "@/models/echart/types";

export default class DigitalSpecificIndicator extends Model {
  static type = "digitalSpecificIndicators";

  code!: string;
  total!: number;
  ongoing!: number;
  completed!: number;
  failed!: number;

  static dummy() {
    const instance = new DigitalSpecificIndicator();
    instance.code = `IG.7${Math.floor(Math.random() * 101)}`;
    instance.total = Math.floor(Math.random() * 101);
    instance.ongoing = Math.floor(Math.random() * 101);
    instance.completed = Math.floor(Math.random() * 101);
    instance.failed = Math.floor(Math.random() * 101);

    return instance;
  }
}
