
import { Vue, Component, Watch, Ref, Mixins } from "vue-property-decorator";

import VDataTable from "@/components/Common/VDataTable.vue";
import Vicon from "@/components/VIcon.vue";

import DigitalSpecificIndicator from "@/models/DigitalSpecificIndicator";
import VFormGroup from "@/components/Common/VFormGroup.vue";
import VTooltip from "@/components/Common/VTooltip.vue";
import DescriptionModal from "@/components/Common/DescriptionModal.vue";
import Throttler from "@/utils/Throttler";
import PageSection from "@/components/Common/PageSection.vue";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { watcherStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";
import Spinner from "@/components/Common/Spinner.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { INDICATOR_DESCRIPTIONS } from "@/utils/Constants";

interface ColumnData {
  sortable: boolean;
  sort: string;
  name: string;
  class?: string;
  default?: string;
  label?: string;
}

@Component({
  components: {
    VDataTable,
    Vicon,
    VFormGroup,
    VTooltip,
    DescriptionModal,
    PageSection,
    Spinner,
  },
})
export default class DigitalSpecificIndicators extends Mixins(IndicatorMixin) {
  private originalValues: DigitalSpecificIndicator[] = [];
  private values: DigitalSpecificIndicator[] = [];

  private term = "";

  private throttler = new Throttler(100);

  @Ref() readonly descriptionModal!: DescriptionModal;

  private colSort: { col: string; sort: string } = { col: "", sort: "" };

  @RegisterHttp(IndicatorMixinTags.DigitalSpecific)
  indicatorsRequest!: RequestData;

  created() {
    this.getIndicators();
    StoreSubscriber.subscribe("_setFilter", this.onFilterChanged);
  }

  @Watch("indicators", { deep: true })
  onIndicatorsChanged() {
    this.values = this.originalValues.map((el: DigitalSpecificIndicator) =>
      Object.assign({}, el)
    );
  }

  @Watch("term")
  onTermChanged() {
    this.filterValues();
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get titleSize() {
    return this.isMobile ? "xs" : "sm";
  }

  get loading() {
    return this.indicatorsRequest?.loading;
  }

  get filter() {
    return watcherStore.filter;
  }

  get payload() {
    return {
      filter: {
        ...this.filter,
      },
    };
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.getIndicators();
    });
  }

  private async getIndicators() {
    const data = await watcherStore.getDigitalIndicatorsData();

    if (data) {
      this.originalValues = data;
      this.values = data;
    } else {
      this.originalValues = [];
    }
  }

  private onIndicatorSelected(rowData: any) {
    const indicator = this.values.find(
      (el: DigitalSpecificIndicator) => el.code === rowData.code
    );

    const description = INDICATOR_DESCRIPTIONS[indicator.code].long;

    this.throttler.run(() => {
      this.descriptionModal.show(description, `Indicatore ${indicator.code}`);
    });
  }

  get computedValues() {
    return this.values;
  }

  get columns(): ColumnData[] {
    return [
      { name: "code", label: "Indicatore", sortable: true, sort: "code" },
      { name: "total", label: "Totale", sortable: true, sort: "total" },
      { name: "ongoing", label: "In corso", sortable: true, sort: "ongoing" },
      {
        name: "completed",
        label: "Raggiunto",
        sortable: true,
        sort: "completed",
      },
      {
        name: "failed",
        label: "Non raggiunto",
        sortable: true,
        sort: "failed",
      },
    ];
  }

  get sortableCols(): string {
    return `${this.colSort.sort}${this.colSort.col}`;
  }

  private onSort(sort: string) {
    this.invertSort(sort);
    sort[0] === "-"
      ? this.sortValues(sort.replace("-", ""), false)
      : this.sortValues(sort);
  }

  private invertSort(col: string) {
    const sortDirection = col[0] === "-" ? "-" : "";
    this.colSort.sort = sortDirection;
    this.colSort.col = col.replace("-", "");
  }

  private sortValues(key: string, desc = true) {
    function sortValues(a: any, b: any) {
      let parsedA =
        key === "code"
          ? parseInt(a[key].toLowerCase().replace("is", ""))
          : a[key];

      let parsedB =
        key === "code"
          ? parseInt(b[key].toLowerCase().replace("is", ""))
          : b[key];

      let first = parsedB;
      let second = parsedA;

      if (!desc) {
        first = parsedA;
        second = parsedB;
      }

      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    }

    this.values = this.values.sort(sortValues);
  }

  private filterValues() {
    if (this.term === "") {
      this.values = this.originalValues.map((el: DigitalSpecificIndicator) =>
        Object.assign({}, el)
      );
      return;
    }

    this.values = this.values.filter((el: DigitalSpecificIndicator) =>
      el.code.toLowerCase().includes(this.term.toLowerCase())
    );
  }
}
